<template>
  <div class="parent">
    <div class="row margin-0">
      <div class="col-12 pad-0">
        <div class="parent1">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'About' }" @click="tab = 'About'">
                About
              </li>
              <li
                :class="{ active: tab === 'Enrollments' }"
                @click="tab = 'Enrollments'"
              >
                Enrollments
              </li>
              <li
                :class="{ active: tab === 'Questionaire' }"
                @click="tab = 'Questionaire'"
              >
                Questionaire
              </li>
            </ul>
          </div>
          <div class="parent-body pad-0">
            <div v-if="tab === 'About'" class="row margin-0">
              <div class="col-6 pad-left-0">
                <div class="info-block">
                  <label for="">Course</label>
                  <p>{{ enquiry.course.title }}</p>
                </div>
              </div>
              <template
                v-if="
                  enquiry.enquiry_type === 'REQUEST' ||
                  enquiry.enquiry_type === 'ANNOUNCEMENT'
                "
              >
                <div class="col-6 pad-left-0">
                  <div class="info-block">
                    <label for="">Location</label>
                    <p>
                      {{
                        enquiry.form.location ? enquiry.form.location.name : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-4 pad-left-0"
                  v-if="enquiry.enquiry_type === 'ANNOUNCEMENT'"
                >
                  <div class="info-block">
                    <label for="">Free Demo</label>
                    <p>{{ enquiry.form.free_demo }}</p>
                  </div>
                </div>
                <div
                  class="col-4 pad-left-0"
                  v-if="enquiry.enquiry_type === 'ANNOUNCEMENT'"
                >
                  <div class="info-block">
                    <label for="">Mode</label>
                    <p>{{ enquiry.form.mode }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Type</label>
                    <p>{{ enquiry.form.type }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Assignment</label>
                    <p>{{ enquiry.form.assignment }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Frequency</label>
                    <p>{{ enquiry.form.frequency }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Language</label>
                    <p>{{ enquiry.form.language }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Fee Type</label>
                    <p>{{ enquiry.form.fee_type }}</p>
                  </div>
                </div>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Estimated Fee</label>
                    <p>{{ enquiry.owner.region }} {{ enquiry.form.fee }}</p>
                  </div>
                </div>
                <template v-if="enquiry.enquiry_type === 'REQUEST'">
                  <div class="col-4 pad-left-0">
                    <div class="info-block">
                      <label for="">Max Unlocks</label>
                      <p>{{ enquiry.unlocks }}</p>
                    </div>
                  </div>
                  <div class="col-4 pad-left-0">
                    <div class="info-block">
                      <label for="">Coins</label>
                      <p>{{ enquiry.course.enquiry_coins }}</p>
                    </div>
                  </div>
                </template>
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Fee Negotiable</label>
                    <p>{{ enquiry.form.fee_negotiable }}</p>
                  </div>
                </div>
                <div
                  class="col-4 pad-left-0"
                  v-if="enquiry.enquiry_type === 'REQUEST'"
                >
                  <div class="info-block">
                    <label for="">#Students</label>
                    <p>{{ enquiry.form.student_count || "-" }}</p>
                  </div>
                </div>
                <div
                  class="col-4 pad-left-0"
                  v-if="enquiry.enquiry_type === 'REQUEST'"
                >
                  <div class="info-block">
                    <label for="">Is Urgent</label>
                    <p>{{ enquiry.form.is_urgent }}</p>
                  </div>
                </div>
              </template>
              <template v-if="enquiry.enquiry_type === 'DEMO'">
                <div class="col-4 pad-left-0">
                  <div class="info-block">
                    <label for="">Preference</label>
                    <p>{{ enquiry.form.preference }}</p>
                  </div>
                </div>
              </template>
              <div class="col-4 pad-left-0">
                <div class="info-block">
                  <label for="">Created On</label>
                  <p>
                    {{
                      $filters.fullDate(
                        enquiry.created_on,
                        "DD-MMM-YYYY, h:mm A"
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-4 pad-left-0">
                <div class="info-block">
                  <label for="">Archieve On</label>
                  <p>
                    {{
                      $filters.fullDate(
                        enquiry.archive_on,
                        "DD-MMM-YYYY, h:mm A"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="tab === 'Enrollments'">
              <Enrollments :enquiry="enquiry" />
            </div>
            <div v-else-if="tab === 'Questionaire'">
              <template
                v-if="
                  enquiry.questionnaire &&
                  Object.keys(enquiry.questionnaire).length > 0
                "
              >
                <ul class="list-inline">
                  <template
                    v-for="(item, idx) in Object.keys(enquiry.questionnaire)"
                    :key="idx"
                  >
                    <li>
                      <div class="info-block">
                        <label for=""
                          >- {{ enquiry.questionnaire[item].question }}</label
                        >
                        <p>{{ enquiry.questionnaire[item].value }}</p>
                      </div>
                    </li>
                  </template>
                </ul>
              </template>
              <template v-else> No Questionaires </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Enrollments from "../Enrollments.vue";
export default {
  props: ["enquiry"],
  components: {
    Enrollments,
  },
  data() {
    return {
      tab: "Enrollments",
    };
  },
};
</script>