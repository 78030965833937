<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': !user, 'pad-0': user}">
        <div class="parent">
          <div class="parent-head" v-if="!user">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Enquires</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Enquiry Type</b></label>
                  <select
                    name=""
                    id=""
                    v-model="enquiry_type"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="REQUEST">REQUEST</option>
                    <option value="DEMO">DEMO</option>
                    <option value="ANNOUNCEMENT">ANNOUNCEMENT</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="OPEN">OPEN</option>
                    <option value="ARCHIEVED">ARCHIEVED</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter();status=null;enquiry_type=null;getdataList()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>User</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'enquiry_type' || sort_by == '-enquiry_type',
                    }"
                    @click="sortBy('enquiry_type')"
                  >
                    Enquiry TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'enquiry_type',
                        'fa-sort-up': sort_by == 'enquiry_type',
                        'fa-sort-down': sort_by == '-enquiry_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                  >
                    COURSE
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th>Created on</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td @click="
                          selectedProduct === item.id
                            ? (selectedProduct = null)
                            : (selectedProduct = item.id)
                        ">
                        <span
                          class="fa txt-grey"
                          :class="{
                            'fa-chevron-right': selectedProduct !== item.id,
                            'fa-chevron-down': selectedProduct === item.id,
                          }"
                        ></span>
                        {{ item.owner?item.owner.first_name:'-' }} {{ item.owner?'- '+item.owner.user_id:'' }}</td>
                      <td>{{ item.enquiry_type }}</td>
                      <td>{{ item.course?item.course.title:'-' }}</td>
                      <td><status :status="item.status" /></td>
                      <td>{{$filters.fullDate(item.created_on, 'DD-MMM-YYYY, h:mm A')}}</td>
                    </tr>
                    <tr v-if="selectedProduct === item.id">
                      <td
                        colspan="6"
                        style="background-color: #f5f7fa"
                        class="pad-5"
                      >
                        <enquiry-detail
                          @updated="getdataList()"
                          :enquiry="item"
                        ></enquiry-detail>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EnquiryDetail from "./components/EnquiryDetail";
import DataMixin from "../mixins/dataMixin";
export default {
  props: ['user'],
  components: {
    EnquiryDetail
  },
  data() {
    return {
      serviceModule: "enquires",
      selectedProduct: null,
      status: null,
      enquiry_type: null,
      defaultPayload: {
        title: null,
        description: null,
        price: null,
        credits: null,
        order: null,
        validity: null,
        rules: null,
        status: true,
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      let extra = {};
      if(this.status) {
        extra.status = this.status;
      }
      if(this.enquiry_type) {
        extra.enquiry_type = this.enquiry_type;
      }
      if(this.user) {
        extra.owner = this.user.id;
      }
      return extra;
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      if (that.payload.credits === null || that.payload.credits === "") {
        this.error.credits = "Credits is required";
        proceed = false;
      }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>